import { useEffect, useState } from "react";

import {colors} from "styles/variables"

export const useTextAndBorderColor = (statusText) => {
  const [textColor, setTextColor] = useState("");
  const [borderColor, setBorderColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");

  useEffect(() => {
    switch (statusText) {
        case 'Available':
          setTextColor(colors.lightGreen)
          setBorderColor(colors.smoothGreen)
          break
        case 'Ringing':
          setTextColor(colors.lightOrange)
          setBorderColor(colors.smoothOrange)
          break
        case 'On Call':
          setTextColor(colors.lightRed)
          setBorderColor(colors.smoothRed)
          break
        case 'Unavailable':
          setTextColor(colors.gray)
          setBorderColor(colors.smoothGray)
          setBackgroundColor(colors.lightGray)
          break
        case 'Calling':
          setTextColor(colors.gray)
          setBorderColor(colors.smoothGray)
          setBackgroundColor(colors.lightGray)
          break
        case 'DND':
          setTextColor(colors.gray)
          setBorderColor(colors.smoothGray)
          setBackgroundColor(colors.lightGray)
          break
        default:
          break
        }
  }, [statusText]);

  return { textColor, borderColor, backgroundColor };
}
