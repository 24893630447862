
import NewDashboardUsers from "./dashboard-users/NewDashboardUsers";
const Dashboard = () => {
  return (
    <>
      <NewDashboardUsers />
    </>
  );
};

export default Dashboard;
