export const getConversationItem = (inboundChannel, outboundChannel, endpointData) => {
  const conversationItem = endpointData.find(item => item.extension === inboundChannel.calleeIDNumber)
  
  //! TODO: Fix ternary operators
  return{
    id: inboundChannel.uuid,
    time: '01:13',
    active: false,
    sound: false,
    num: inboundChannel.destination.length > 3 ? '201' : inboundChannel.destination,
    place: 'Salon',
    name: conversationItem ? conversationItem.name : 'Michael Johnson',
    role: 'Sales',
    phone: inboundChannel.callerDestination,
    status: inboundChannel.callState,
  }
}
