import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import cogoToast from "cogo-toast";

export const googleAuthLogin = createAsyncThunk("items/fetchGoogleAuth", async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_CONTACTS_URL}/contact/generateurl?redirect_uri=${data.redirect_url}`, { contactData: data.contactData }, { withCredentials: true });
    return response.data;
})

export const googleAuthAccessToken = createAsyncThunk("items/fetchGoogleAuthAccessToken", async (data) => {
    const response = await axios.get(`${process.env.REACT_APP_CONTACTS_URL}/contact/getAccessToken?code=${data.code}&redirect_uri=${data.redirect_uri}`, { withCredentials: true });
    return response.data;
})

export const googleAuthRefreshToken = createAsyncThunk("items/fetchGoogleAuthRefreshToken", async (data) => {
    const response = await axios.get(`${process.env.REACT_APP_CONTACTS_URL}/contact/getRefreshAccessToken?code=${data.refreshToken}&redirect_uri=${data.redirect_uri}`, { withCredentials: true });
    return response.data;
})

const initialState = {
    data: {},
    status: "idle",
    error: null,
}

const googleAuthSlice = createSlice({
    name: "googleAuth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(googleAuthLogin.pending, (state) => {
                state.status = "loading"
                cogoToast.loading("You will be redirected to the Google consent page shortly.", { hideAfter: 5 })

            })
            .addCase(googleAuthLogin.fulfilled, (state, action) => {
                state.status = "succeeded"
                if (action.payload.data) {
                    window.location.href = action.payload.data.url
                }
                // state.data = action.payload
            })
            .addCase(googleAuthLogin.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
                state.data = {}
                cogoToast.error("Something went wrong. Please try again later.")
                // state.error = action.error.message
            })
            .addCase(googleAuthAccessToken.pending, (state) => {
                state.status = "loading"
                cogoToast.loading("Signing with Google. Please wait.", { hideAfter: 5 })
            })
            .addCase(googleAuthAccessToken.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.data = action.payload


            })
            .addCase(googleAuthAccessToken.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
                cogoToast.error("Something went wrong. Please try again later.")
                state.data = {}
            })
            .addCase(googleAuthRefreshToken.pending, (state) => {
                state.status = "loading"

            })
            .addCase(googleAuthRefreshToken.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.data = action.payload


            })
            .addCase(googleAuthRefreshToken.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
                state.data = {}
            })

    }
})

export default googleAuthSlice.reducer;