import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "@data-phone/react-resource-server-auth";

// Create an async thunk to fetch data from the API
export const fetchExtensions = createAsyncThunk("items/extensions", async () => {
  const response = await api.get(`${process.env.REACT_APP_LIVE_CALL_URL}/api/extensions`);
  return response;
});

// Create an async thunk to post a new item to the API

// Define the initial state
const initialState = {
  items: [],
  status: "idle",
  error: null,
};

// Define the slice
const extensionsSlice = createSlice({
  name: "extensions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add the async thunk actions to the reducer
    builder
      .addCase(fetchExtensions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExtensions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchExtensions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the async thunks and the reducer
// export { fetchItems, postItem };
export default extensionsSlice.reducer;
