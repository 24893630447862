import Card from 'components/cards/dnd-groupby-card'
import update from 'immutability-helper'
import { useCallback, useEffect, useState } from 'react'



export const DndGroupByList = ({
  groupBy,
  setGroupBy
}) => {
  {
    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setGroupBy((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }),
      )
    }, [])

    const renderCard = useCallback(
      (card, index) => {
        return (
          <div style={{
            cursor: "move",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "10px"
          }}>
            <Card
              key={card.id}
              index={index}
              id={card.id}
              text={card.text}
              moveCard={moveCard}
            />
            <input checked={card.enabled} onChange={() => setGroupBy((prevCards) => update(prevCards, { [index]: { enabled: { $set: !card.enabled } } }))} id="disabled-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
          </div>
        )
      },
      [],
    )

    return (
      <>

        <div>{groupBy.map((card, i) => renderCard(card, i))}</div>
      </>
    )
  }
}
