import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FaBars, FaThLarge } from "react-icons/fa";

import useLiveActivityData from "hooks/useLiveActivityData";

import useCallSocket from "hooks/useCallSocket";

import { useLocation } from "react-router-dom";
import { fetchRingGroups } from "redux/slices/ringGroups";
import { fetchIvrMenu } from "redux/slices/ivrMenus";
import { fetchExtensions } from "redux/slices/extensions";

import ico_search from "../../../images/images/ico-search.svg";

import ico_filter from "../../../images/images/ico-filter.svg";

import CallDetails from "./call-details";

import CallsList from "./call-list/CallsList";

import "./NewDashboard.css";
import NewUsersList from "./users-list/NewUsersList";
import { fetchAgents } from "redux/slices/agentSlice";
import { fetchCallCenterData } from "redux/slices/callCenterDataSlice";
import { DndGroupByList } from "./dnd-group-by-list";
import OutsideClickHandler from 'react-outside-click-handler';


const NewDashboardUsers = () => {
  const extensions = useSelector((state) => state.extensions.items);
  const callsData = useSelector((state) => state.callDetails.data);
  const liveActivityData = useLiveActivityData(callsData, extensions);
  const [orderByDropDown, setOrderByDropDown] = useState(false);
  const usersData = useSelector((state) => state.usersDataSlice);
  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState({
    all: true,
    ivr: false,
    ring: false,
    extension: false,
    callCenter: false,
  });
  const [view, setView] = useState("list");
  const [groupBy, setGroupBy] = useState([
    {
      id: "ivrMenu",
      text: 'IVR',
      enabled: true,
    },
    {
      id: "ringGroup",
      text: 'Ring Group',
      enabled: true,
    },
    {
      id: "queue",
      text: 'Queue',
      enabled: true,
    },
    {
      id: "extensions",
      text: 'Extensions',
      enabled: true,
    },
    {
      id: "otherCalls",
      text: 'Other calls',
      enabled: true,
    },
  ])


  const handleView = (view) => {
    setView(view);
  };
  const location = useLocation();
  const myParam = new URLSearchParams(location.search).get("code");
  if (myParam) {
    console.log("code ", myParam);
  }

  const dispatch = useDispatch();

  const { handleRecord, handleBridgeCall,handleTransferCall } = useCallSocket();

  useEffect(() => {
      dispatch(fetchIvrMenu());
      dispatch(fetchRingGroups());
      dispatch(fetchExtensions());
      dispatch(fetchCallCenterData())
    }
  , [ ]);
  return (
    <section className="flex h-full">
      <div className="flex-[4_2_0%] bg-grey">
        <div className="px-7 py-4">
          <h1 className="text-2xl font-medium">Live Activity</h1>
        </div>

        <CallDetails cloumnData={liveActivityData} />
        <div className="pt-3 px-7 pb-7">
          <div className="flex justify-between">
            <div className="flex">
              <div className="input-group py-2 flex bg-white rounded w-52 borderRound">
                <button type="button" className="pl-2 pr-1">
                  <img src={ico_search} alt="" />
                </button>
                <input
                  type="text"
                  placeholder="Search"
                  className="focus:outline-none text-xs font-medium"
                  onChange={(event) => {
                    setSearchInput(event.target.value);
                  }}
                  value={searchInput}
                />
              </div>

              <div className="relative inline-block text-left">
                <div>
                  <button
                    onClick={() => setOrderByDropDown(!orderByDropDown)}
                    type="button"
                    className="flex items-center ml-1 bg-white rounded px-3 py-2 borderRound"
                  >
                    <span>
                      {" "}
                      <img src={ico_filter} alt="" />
                    </span>
                    <span className="colorGrey pl-1 text-xs font-medium">
                      Group by
                    </span>
                  </button>
                </div>

                <OutsideClickHandler onOutsideClick={() => setOrderByDropDown(false)}>
                  <div className={`absolute ${orderByDropDown ? "" : "hidden"} right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div className="py-1" role="none">
                      <DndGroupByList groupBy={groupBy} setGroupBy={setGroupBy} />
                    </div>
                  </div>
                </OutsideClickHandler>
              </div>

            </div>
            <div>
              <div id="btnContainer">
                <button
                  className={`btn py-2 px-3 borderRound rounded bg-white ${view == "list" ? "active" : ""
                    }`}
                  onClick={() => {
                    handleView("list");
                  }}
                >
                  <FaBars />
                </button>
                <button
                  className={`btn py-2 px-3 borderRound rounded bg-white  ${view == "grid" ? "active" : ""
                    }`}
                  onClick={() => {
                    handleView("grid");
                  }}
                >
                  <FaThLarge />
                </button>
              </div>
            </div>
          </div>
          {view == "grid" && (
            <div
              className="card-wrapper py-2 rounded borderRound border-0 "
              style={{ height: "100vh" }}
              id="gridView"
            >

              <CallsList handleTransferCall={handleTransferCall} groupBy={groupBy} filters={filters} handleRecord={handleRecord} handleBridgeCall={handleBridgeCall} searchInput={searchInput} display={view} />
            </div>
          )}
          {view == "list" && (
            <div
              className="card-wrapper mt-2 overflow-auto touch-pan-x "
              style={{ height: "100vh" }}
              id="listView"
            >
              <CallsList handleTransferCall={handleTransferCall} groupBy={groupBy} filters={filters} handleRecord={handleRecord} handleBridgeCall={handleBridgeCall} searchInput={searchInput} display={view} />
            </div>
          )}
        </div>
      </div>
      <NewUsersList handleTransferCall={handleTransferCall} handleBridgeCall={handleBridgeCall} />
    </section>
  );
};

export default NewDashboardUsers;
