import { useState, useEffect } from 'react';
import { colors } from 'styles/variables';


const useCallStateBorderColor = (info) => {
  const [borderColor, setBorderColor] = useState("");

  useEffect(() => {
    switch (info.answerState) {
      case 'ringing':
        setBorderColor(colors.smoothOrange);
        break;
      case 'answered':
        setBorderColor(colors.smoothRed);
        break;
      case 'hold':
        setBorderColor(colors.smoothPurple);
        break;
      default:
        break;
    }
  }, [info, colors]);

  return borderColor;
};

export default useCallStateBorderColor;