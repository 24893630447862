import OnCallItem from "pages/dashboard/dashboard-users/call-list/call-item/OnCallItem";

import { useMemo } from "react";
import { useSelector } from "react-redux";

const useMemoizedOnCallItem = (callsData, timersHook,sortBy,searchInputOnCallList) => {

  const extensions = useSelector((state) => state.extensions.items);
  let sortedCallsData = [...callsData] || []
  const memoizedCallItems = useMemo(() => {
    return sortedCallsData
    .sort((a, b) => {
      const extensionA = a?.callerIDNumber
      const extensionB = b?.callerIDNumber
      if(a.callerIDName && b.callerIDName){
        const nameA = a?.callerIDName.toLowerCase(); // Convert names to lowercase for case-insensitive comparison
        const nameB = b?.callerIDName.toLowerCase();
        if (sortBy == "aToZ") {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
        if (sortBy == "bToZ") {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        }
      }

      if (sortBy == "extensionAsscending") {
        if (extensionA < extensionB) {
          return -1;
        }
        if (extensionA > extensionB) {
          return 1;
        }
        return 0;
      }
      if (sortBy == "extensionDescending") {
        if (extensionA > extensionB) {
          return -1;
        }
        if (extensionA < extensionB) {
          return 1;
        }
        return 0;
      }
    }).filter((item) => {
      if (item) {
        const searchPattern = new RegExp(searchInputOnCallList, "i");
        return (
          searchPattern.test(item?.callerIDNumber) ||
          searchPattern.test(item?.callerIDName)
        );
      }
    })
    .map((item) => {
      if (
        extensions &&
        extensions.result &&
        extensions.result.length > 0 &&
        extensions?.result?.some(
          (extension) =>
            extension.extension == item.callerIDNumber ||
            extension.extension == item.destination 
            // item.callees.some((cal) => cal.number == extension.extension)
        )
      ) {
        return (
          <>
            <OnCallItem
              key={item.callUUID}
              phone={item.callerIDNumber}
              name={item.callerIDName}
              time={
                timersHook[item.callUUID] ? timersHook[item.callUUID] : "00:00"
              }
              info={item}
              status={item.direction}
            />
            {item.callees.length > 0 &&
              item.isOutgoing &&
              Array.from(
                new Map(
                  item.callees.map((item) => [item.number, item])
                ).values()
              ).map((callee) => {
                let calleeItem = {
                  ...item,
                  destination: item.callerIDNumber,
                  incoming: true,
                };
                if (
                  extensions &&
                  extensions.result.length > 0 &&
                  extensions.result.some(
                    (extension) => extension.extension == callee.number
                  )
                ) {
                  return (
                    <OnCallItem
                      key={calleeItem.callUUID}
                      phone={callee.number}
                      name={callee.name}
                      time={
                        timersHook[calleeItem.callUUID]
                          ? timersHook[calleeItem.callUUID]
                          : "00:00"
                      }
                      info={calleeItem}
                      status={callee.answerState}
                    />
                  );
                }
              })}
          </>
        );
      }
    });
  }, [callsData, timersHook]);

  return memoizedCallItems;
};

export default useMemoizedOnCallItem;
