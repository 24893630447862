// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for the web

import rootReducer from '../slices/appSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['googleAuth'],
  // Add any other config options here
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // Add any other store configuration here
});

const persistor = persistStore(store);

export { store, persistor };
