import { generateNameSpaceColor, hslToHashCode } from "helper";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AgentsCard = ({ selectedDepartment }) => {
  const agents = useSelector((state) => state.agentsSlice.items.result);
  const active = useSelector((state) => state.activeAgents.items.result);
  const agentsOffering = useSelector((state) => state.agentsOffering.items);
  const callCenterCallsData = useSelector(
    (state) => state.callCenterCallSlice.items
  );
  const queues = useSelector((state) => state.callCenterDataSlice.items.result);
  const activecallCenterSlice = useSelector(
    (state) => state.activeCallCenterSlice.items
  );

  const [sortBy, setSortBy] = useState("extensionAsscending")



  return (
    <>
      <select
        style={{
          padding: '0.5rem 1rem',
          borderRadius: '999px',
          fontSize: '0.875rem',
          fontWeight: '500',
          cursor: 'pointer',
          border: '1px solid #CCCCCC',
          backgroundColor: 'rgb(238 238 238 / var(--tw-bg-opacity))',
          width: "100%"
        }}
        className="form-select"
        aria-label="Default select example"
        onChange={(e) => setSortBy(e.target.value)}
      >
        <option value="extensionAsscending">Extension (Ascending)</option>
        <option value="extensionDescending">Extension (Descending)</option>
        <option value="aToZ">Name (Ascending)</option>
        <option value="bToZ">Name (Descending)</option>

      </select>
      {agents
        ?.filter((ag) => {
          if (selectedDepartment.length == 0) {
            return ag;
          } else {
            const hasAgentInSelectedDepartment = selectedDepartment.some(
              (department) => {
                if (active && active.queues && active.queues.length > 0) {
                  return active.queues.some((queue) => {
                    if (selectedDepartment.includes(queue.name)) {
                      if (queue && queue.tiers && queue.tiers.length > 0) {
                        return queue.tiers.some((t) => t.agent === ag.name);
                      }
                    }
                    return false;
                  });
                }
                return false;
              }
            );

            return hasAgentInSelectedDepartment;
          }
        })

        .sort((a, b) => {
          const nameA = a.description.toLowerCase(); // Convert names to lowercase for case-insensitive comparison
          const nameB = b.description.toLowerCase();
          const extensionA = a.extension
          const extensionB = b.extension
          if (sortBy == "aToZ") {
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }
          if (sortBy == "bToZ") {
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          }
          if (sortBy == "extensionAsscending") {
            if (extensionA < extensionB) {
              return -1;
            }
            if (extensionA > extensionB) {
              return 1;
            }
            return 0;
          }
          if (sortBy == "extensionDescending") {
            if (extensionA > extensionB) {
              return -1;
            }
            if (extensionA < extensionB) {
              return 1;
            }
            return 0;
          }

        })
        .map((data) => {
          let departmentName = "";
          let departColor = [];
          let departBorderColor = "";
          if (active && active.queues && active.queues.length > 0) {
            active.queues.forEach((q) => {
              if (q.tiers && q.tiers.length > 0) {
                q.tiers.forEach((tier) => {

                  if (tier.agent == data.name) {
                    if (queues && queues.length > 0) {
                      queues.forEach((queue) => {
                        if (queue.extension == q.name) {
                          let textColor = generateNameSpaceColor(queue.name, 40)
                          departmentName =
                            departmentName.length > 0
                              ? departmentName + " , " + queue.name
                              : queue.name;
                          departColor.push(textColor);
                        }
                      });
                    } else {
                      departmentName = q.name;
                    }
                  }
                });
              }
            });
          }

          if (active && active.agents && active.agents.length > 0) {
            active.agents.forEach((agent) => {
              if (agent.name == data.name && agent.state != "Waiting") {
                departBorderColor = departColor;
              } else {
                departBorderColor = "";
              }
            });
          }

          return (
            <div
              className={`w-full border border-[${departBorderColor}] rounded-[8px] py-[12px] px-[16px] shadow`}
              key={data.name}
              style={{ borderWidth: "3px !important" }}
            // style={{ borderColor: `${departBorderColor}`, borderWidth: "3px !important" }}
            >
              <div className="flex gap-[10px] items-center mb-2 justify-between">
                <div className="flex gap-[4px] items-center">
                  <div className="bg-[#F2F2F2] py-[2px] px-[6px] text-[10px] text-[#686868] rounded-[4px]">
                    {data.extension}
                  </div>
                  <h6 className="text-xs text-[#000] font-medium">
                    {data.description}
                  </h6>
                </div>
                { }
                <span className="text-[10px] ">
                  {/* {departColor.length>0?departColor.split(",")[0]} */}
                  {/* <span lassName="text-[10px] " >
                    {departmentName}
                  </span>
                  <span lassName="text-[10px] " >
                    {departmentName}
                  </span> */}
                  {departmentName.split(",").length > 0 &&
                    departmentName.split(",").map((item, index) => {
                      return (
                        <span key={index}>
                          <span
                            className="text-[10px] "
                            style={{
                              color:
                                item !== "," && departColor[index],
                            }}
                          >
                            {item}
                          </span>
                        </span>
                      );
                    })}
                </span>
              </div>
              {/* {active.agents.find((item) => item.name == data.name).length > 0 && (
            <div className="text-xs font-semibold text-[#54CA2B]">
              {active.agents.find((item) => item.name == data.name)[0].state}
            </div>
          )} */}
              {active && active.agents && active.agents.length > 0 && active.agents.map((agent) => {
                let statusText = "";
                if (agentsOffering && agentsOffering.length > 0) {
                  let agentOffering = agentsOffering.find(
                    (offering) => offering.agent.split("@")[0] == data.name
                  );
                  if (agentOffering) {
                    let queueCallCenter;
                    if (callCenterCallsData.length > 0) {
                      queueCallCenter = callCenterCallsData.find(
                        (call) => call.sessionUUID == agentOffering.sessionUUID
                      );
                      let queueName = "";
                      if (queueCallCenter) {
                        if (queueCallCenter.queueName) {
                          if (queues && queues.length > 0) {
                            queues.forEach((q) => {
                              if (q.extension == queueCallCenter.queueName) {
                                queueName = q.name;
                              }
                            });
                          }
                          statusText =
                            queueName +
                            " " +
                            (queueCallCenter.memberCIDNumber ||
                              queueCallCenter.callerIDNumber);
                        } else {
                          if (queues && queues.length > 0) {
                            queues.forEach((q) => {
                              if (
                                q.extension ==
                                queueCallCenter.queue.split("@")[0]
                              ) {
                                queueName = q.name;
                              }
                            });
                          }
                          statusText =
                            queueName +
                            " " +
                            (queueCallCenter.memberCIDNumber ||
                              queueCallCenter.callerIDNumber);
                        }
                      }
                    }
                  }
                }
                if (activecallCenterSlice && activecallCenterSlice.length > 0) {
                  activecallCenterSlice.forEach((call) => {
                    let queueName = "";
                    if (data.name == call?.name?.split("@")[0]) {
                      if (queues && queues.length > 0) {
                        queues.forEach((q) => {
                          if (call.queueName) {
                            if (q.extension == call.queueName) {
                              queueName = q.name;
                            }
                          } else if (q.extension == call.queue.split("@")[0]) {
                            queueName = q.name;
                          }
                        });
                      }
                      statusText =
                        queueName + " " + call.memberCIDNumber ||
                        call.callerIDNumber;
                    }
                  });
                }
                {
                  if (agent.status !== "Logged Out") {
                    if (agent.name == data.name) {
                      return (
                        <div className="flex gap-[10px] items-center mb-2 justify-between text-xs font-semibold text-[#54CA2B]">
                          <span>
                            {agent.state} {statusText}
                          </span>
                          <span>
                            {active &&
                              active.queues &&
                              active.queues.length > 0 &&
                              active.queues.flatMap((queue) => {
                                let text = queue.tiers && queue.tiers
                                  .filter((tier) => tier.agent === agent.name)
                                  .map((matchingTier) => `Tier ${matchingTier.level} `)
                                  .join(", ");
                                let textColor = generateNameSpaceColor(text, 40)
                                return (
                                  <span style={{ color: textColor }} className="text-[10px] ">
                                    {text}
                                  </span>
                                )
                              })}
                          </span>
                        </div>
                      );
                    }
                    //  generateNameSpaceColor(`Tier ${matchingTier.level} `,40)
                  }
                  {
                    if (agent.status == "Logged Out") {
                      if (agent.name == data.name) {
                        return (
                          <div className="flex gap-[10px] items-center mb-2 justify-between text-xs font-semibold text-[#CA2B2B]">
                            <span>
                              {agent.status}
                            </span>
                          </div>)
                      }

                    }
                  }
                }

              })}
            </div>
          );
        })}
    </>
  );
};

export default AgentsCard;
