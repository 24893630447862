import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { generateNameSpaceColor } from "helper";

import personIcoTimer from "../../images/images/ico-persons.svg";

import departmentIcon from "../../images/images/department-icon.svg";

const DepartmentCard = ({ setSelectedDepartment, selectedDepartment }) => {
  const active = useSelector((state) => state.activeAgents.items.result);
  const queues = useSelector((state) => state.callCenterDataSlice.items.result);
  const callCenterCallsData = useSelector(
    (state) => state.callCenterCallSlice.items
  );

  const activecallCenterSlice = useSelector(
    (state) => state.activeCallCenterSlice.items
  );

  const [finalDepartment, setFinalDepartment] = useState([]);
  const [sortBy, setSortBy] = useState("aToZ")


  useEffect(() => {
    if (queues && active) {
      let finalDepart = [];
      active.queues.length > 0 && active.queues.forEach((element) => {
        queues.forEach((data) => {
          if (element.name == data.extension) {
            let obj = { ...element, ...data };
            finalDepart.push(obj);
          }
        });
      });
      setFinalDepartment(finalDepart);
    }
  }, [queues, active]);


  return (
    <>
      <select
        style={{
          padding: '0.5rem 1rem',
          borderRadius: '999px',
          fontSize: '0.875rem',
          fontWeight: '500',
          cursor: 'pointer',
          border: '1px solid #CCCCCC',
          backgroundColor: 'rgb(238 238 238 / var(--tw-bg-opacity))',
          width: "100%"
        }}
        className="form-select"
        aria-label="Default select example"
      onChange={(e) => setSortBy(e.target.value)}
      >
        <option value="aToZ">Name (Ascending)</option>
        <option value="bToZ">Name (Descending)</option>
      </select>
      {
        finalDepartment.sort((a, b) => {
          const nameA = a.name.toLowerCase(); // Convert names to lowercase for case-insensitive comparison
          const nameB = b.name.toLowerCase();
          if (sortBy == "aToZ") {
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }
          if (sortBy == "bToZ") {
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          }
        }).map((data) => {
          let departColor = generateNameSpaceColor(data?.name, 40)


          return (
            <div
              className={`w-full border border   rounded-[8px] p-2 flex flex-wrap gap-[5px] shadow relative cursor-pointer`}
              style={{
                borderColor:
                  selectedDepartment.includes(data.extension) ? departColor : "",
                borderWidth: "3px"
              }}
              key={data.extension}
              onClick={() => {
                if (selectedDepartment.includes(data.extension)) {
                  let departs = selectedDepartment.filter(item => item !== data.extension)
                  setSelectedDepartment(departs);
                } else {
                  setSelectedDepartment([...selectedDepartment, data.extension]);
                }
              }}
            >
              <div className="w-[24px] h-[24px] rounded-[100%] p-[5px] bg-[#12a9fe45]">
                <img src={departmentIcon} className="w-full" />
              </div>
              <div className="text-left">
                <h6
                  className="text-xs text-[#000] font-medium mb-2"
                  style={{ color: departColor }}
                >
                  {data.name}

                  {/* {data.active} */}
                </h6>
                <div>
                  <span className="text-xs font-semibold text-[#55C52D] py-1 px-2">
                    {/* {data.tiers.filter((item) => item.state == "Ready").length}{" "} */}
                    {
                      activecallCenterSlice.filter((item) => {
                        if (item.queueName) {
                          return item.queueName == data.extension;
                        } else {
                          return item.queue.split("@")[0] == data.extension;
                        }
                      }).length
                    }
                    &nbsp; Answered calls
                  </span>
                  <span className="text-xs font-semibold text-[#D05757] py-1 px-2">
                    {
                      callCenterCallsData.filter((item) => {
                        if (item.queueName) {
                          return item.queueName == data.extension;
                        } else {
                          return item.queue.split("@")[0] == data.extension;
                        }
                      }).length
                    }{" "}
                    Waiting
                  </span>
                </div>
              </div>
              <div className="absolute bg-[#F6F6F6] p-[4px] text-[10px] font-medium text-[#686868] top-0 right-0 flex gap-[5px]">
                <img src={personIcoTimer} width={14} height={14} />
                {data?.tiers.length}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default DepartmentCard;
