import { createSlice } from '@reduxjs/toolkit'
import { find, size, every, remove, map, findIndex } from 'lodash'

import { getConversationItem } from '../helpers/getConversationItem'
import { getNewCallItem } from '../helpers/getNewCallItem'

const INITIAL_STATE = {
  calls: [
    {
      id: '1',
      time: '00:13',
      phone: '(780) 773-5621',
      name: 'Camping',
      info: 'Ringing to Isabella',
      status: 'outgoing'
    },
    {
      id: '2',
      time: '00:13',
      phone: '(780) 773-5621',
      name: 'Camping',
      info: 'IVR Day Greeting',
      status: 'inbound'
    },
    {
      id: '3',
      time: '00:13',
      phone: '(780) 773-5621',
      name: 'Camping',
      info: 'Parked',
      status: 'parked'
    },
    {
      id: '4',
      time: '00:13',
      phone: '(780) 773-5621',
      name: 'Camping',
      info: 'Ringing to Isabella',
      status: 'outgoing'
    },
    {
      id: '5',
      time: '00:13',
      phone: '(780) 773-5621',
      name: 'Camping',
      info: 'IVR Day Greeting',
      status: 'inbound'
    },
  ],
  conversations: [
    {
      id: '1',
      time: '01:13',
      active: true,
      sound: true,
      num: '259',
      place: 'Salon',
      name: 'Alexandra H',
      role: 'Sales',
      phone: '(602) 277-2450',
      status: 'Active',
    },
    {
      id: '2',
      time: '01:13',
      active: false,
      sound: false,
      num: '259',
      place: 'Salon',
      name: 'Alexandra H',
      role: 'Sales',
      phone: '(602) 277-2450',
      status: 'Active',
    },
    {
      id: '3',
      time: '01:13',
      active: true,
      sound: false,
      num: '259',
      place: 'Salon',
      name: 'Alexandra H',
      role: 'Sales',
      phone: '(602) 277-2450',
      status: 'Active',
    },
  ]
}

export const callsSlice = createSlice({
  name: 'calls',
  initialState: INITIAL_STATE,
  reducers: {
    setCalls: (state, action) => {
    //   console.log("action payload ",action.payload)
    //   let inboundChannel = action.payload.filter(item=>item.direction == "inbound")
    //   let outboundChannel = action.payload.filter(item=>item.direction == "outbound")
    //   console.log("inbound channel",outboundChannel)
      
    // let newCalls =   action.payload.map(item=>{
    //     return getNewCallItem(inboundChannel,outboundChannel)
    //   })
    //   console.log("newCalls",newCalls)
      // state.calls.push({
      //   id: "1",
      //   time: '00:00',
      //   phone:"123123123123",
      //   name:  'name',
      //   info:"asd",
      //   status: "direction",
      // })
      // console.log("action",action)
      const inboundChannel = find(action.payload.calls, { direction: 'inbound' })
      const outboundChannel = find(action.payload.calls, { direction: 'outbound' })
      // console.log("inbound channel",inboundChannel)
      const index = findIndex(state.calls, { id: inboundChannel.uuid })
      // console.log("index",index)
      const allAnswered = every(action.payload.calls, { answerState: 'answered' })


      if (index !== -1) {
        state.calls[index] = getNewCallItem(inboundChannel, outboundChannel)
        if (inboundChannel.answerState === 'hangup') {
          remove(state.calls, { id: getNewCallItem(inboundChannel, outboundChannel).id })
        }
      } else {
        if (!find(action.payload.calls, { answerState: 'hangup' })) {
          state.calls.push(getNewCallItem(inboundChannel, outboundChannel))
        }
      }

      if (action.payload.calls.length >= 2 && allAnswered) {
        const callIds = map(action.payload.calls, 'uuid')
        const existingIndex = findIndex(state.calls, call => callIds.includes(call.id))
        remove(state.calls, { id: getNewCallItem(inboundChannel, outboundChannel).id })
        if (existingIndex !== -1) {
          remove(state.calls, { id: getNewCallItem(inboundChannel, outboundChannel).id })
        }
      }
    },

    setConversations: (state, action) => {
      const endpointData = action.payload.endpointData
      if (every(action.payload.conversations, { answerState: 'answered' }) && size(action.payload.conversations) > 1) {
        const inboundChannel = find(action.payload.conversations, { direction: 'inbound' })
        const outboundChannel = find(action.payload.conversations, { direction: 'outbound' })
        const index = findIndex(state.conversations, { id: inboundChannel.uuid })

        if (index !== -1) {
          state.conversations[index] = getConversationItem(inboundChannel, outboundChannel, endpointData)
        } else {
          state.conversations.push(getConversationItem(inboundChannel, outboundChannel, endpointData))
        }
      }
      
      if (size(action.payload.conversations) <= 1) {
        const inboundChannel = find(action.payload.conversations, { direction: 'inbound' })
        const conversationItem = find(state.conversations, { id: inboundChannel.uuid })

        if (conversationItem) {
          remove(state.conversations, { id: conversationItem.id })
        }
      }
    }

  },
})

export const { setCalls, setConversations } = callsSlice.actions