import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useStatusText = (extension) => {
  const [statusText, setStatusText] = useState("Available");
  const callsData = useSelector((state) => state.callDetails.data);

  useEffect(() => {
    if (callsData.length > 0) {
      callsData.forEach((element) => {
        if (element.callees.length > 0) {
          if (element.callees.some((item) => item.answerState !== "hangup")) {
            element.callees
              .filter((item) => item.answerState !== "hangup")
              .forEach((callee) => {
                if (callee.number == extension) {
                  if (callee.answerState == "answered") {
                    setStatusText("On Call");
                  } else if (callee.answerState === "answered") {
                    setStatusText("On Call");
                  } else if (callee.answerState === "ringing") {
                    setStatusText("Ringing");
                  } else if (callee.answerState == "hangup") {
                    setStatusText("Available");
                  } else {
                    setStatusText("Available");
                  }
                }
              });
            if (element.destination === extension) {
              if (element.answerState === "answered") {
                setStatusText("On Call");
              } else {
                setStatusText(
                  element.answerState?.charAt(0).toUpperCase() +
                    element.answerState?.slice(1)
                );
              }
            } else if (element.callerIDNumber === extension) {
              if (element.answerState === "answered") {
                setStatusText("On Call");
              } else if (element.answerState === "ringing") {
                setStatusText("Calling");
              }
              // else {
              //   if (element.answerState == "hangup") {
              //     setStatusText("Available");
              //   }
              // }
            }
          }
          else{
            setStatusText("Available");
          }
        } else {
          if (element.destination === extension) {
            if (element.answerState === "answered") {
              setStatusText("On Call");
            } else {
              setStatusText(
                element.answerState?.charAt(0).toUpperCase() +
                  element.answerState?.slice(1)
              );
            }
          } else if (element.callerIDNumber === extension) {
            if (element.answerState === "answered") {
              setStatusText("On Call");
            } else if (element.answerState === "ringing") {
              setStatusText("Calling");
            }
            //  else if (element.answerState === "hangup") {
            //   setStatusText("Available");
            // }
            //  else {
            //   setStatusText(
            //     element.answerState?.charAt(0).toUpperCase() +
            //       element.answerState?.slice(1)
            //   );
            // }
          } else {
            setStatusText("Available");
          }
        }
      });
    } else {
      setStatusText("Available");
    }
  }, [callsData, extension]);

  return { statusText };
};
