import { useEffect, useState } from 'react';

const useLiveActivityData = (callsData, extensions) => {
  const [liveActivityData, setLiveActivityData] = useState({
    incoming: 0,
    outgoing: 0,
    parked: 0,
    totalCalls: 0,
    totalUsers: 0,
  });


  useEffect(() => {
    let dt = {
      incoming: 0,
      outgoing: 0,
      parked: 0,
    };

    callsData.forEach((call) => {
      if (
        extensions?.result?.some((item) => item.extension === call.callerIDNumber)
      ) {
        dt = { ...dt, outgoing: dt.outgoing + 1 };
      } else {
        dt = { ...dt, incoming: dt.incoming + 1 };
      }
      if (call.isParked) {
        dt = { ...dt, parked: dt.parked + 1 };
      } 
    });

    setLiveActivityData({
      incoming: dt.incoming,
      outgoing: dt.outgoing,
      parked: dt.parked,
      totalCalls: callsData.length,
      totalUsers: extensions.result !== undefined ? extensions.result.length : 0,
    });
  }, [callsData, extensions]);

  return liveActivityData;
};

export default useLiveActivityData;