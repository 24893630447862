import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import randomColor from "randomcolor";

import { api } from "@data-phone/react-resource-server-auth";


// Create an async thunk to fetch data from the API
export const fetchCallCenterData = createAsyncThunk(
  "items/callCenterData",
  async () => {
    const response = await api.get(`${process.env.REACT_APP_LIVE_CALL_URL}/api/callcenter/queues`);
    return response;
  }
);

// Create an async thunk to post a new item to the API

// Define the initial state
const initialState = {
  items: [],
  status: "idle",
  error: null,
};

// Define the slice
const callCenterSlice = createSlice({
  name: "callCentersData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add the async thunk actions to the reducer
    builder
      .addCase(fetchCallCenterData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCallCenterData.fulfilled, (state, action) => {
        state.status = "succeeded";

        function generateUniqueRandomColor(usedColors) {
          let newColor;
          do {
            newColor = randomColor();
          } while (usedColors.includes(newColor));
          return newColor;
        }
        
        function appendColorsToObjectArray(arr) {
          const usedColors = []; // Array to store used colors
          
          for (const obj of arr) {
            const uniqueColor = generateUniqueRandomColor(usedColors);
            usedColors.push(uniqueColor); // Store the used color
            obj.color = uniqueColor; // Assign the unique color to the object
          }
        }
        


        // Example usage:
        let resultArr = [...action.payload.result];

        const resultArray = appendColorsToObjectArray(resultArr);

        // state.items.result = resultArray;
        state.items = action.payload;
      })
      .addCase(fetchCallCenterData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the async thunks and the reducer
// export { fetchItems, postItem };
export default callCenterSlice.reducer;
