import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


// Create an async thunk to fetch data from the API

export const createAgentsOffering = createAsyncThunk(
  "items/updateAgentsOffering",
  async (data) => {
    try {
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const removeAgentsOfferingBySessionId = createAsyncThunk(
  "items/removeAgentsOfferingBySessionId",
  async (data) => {
    try {
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const removeAgentsByAgentsId = createAsyncThunk("items/removeAgentsByAgentsId", async (data) => {
    try {
        return data;
    } catch (error) {
        console.log("error", error);
    }
})

// Create an async thunk to post a new item to the API

// Define the initial state
const initialState = {
  items: [],
  status: "idle",
  error: null,
};

// Define the slice
const agentsOfferingSlice = createSlice({
  name: "active",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add the async thunk actions to the reducer
    builder
      .addCase(createAgentsOffering.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAgentsOffering.fulfilled, (state, action) => {
        state.status = "succeeded";

        const callIndex = state.items.findIndex(
          (call) => call.agent === action.payload.agent
        );

        // // Update the agent state if the agent is found
        if (callIndex !== -1) {
          state.items[callIndex] = action.payload;
        } else {
          state.items = [...state.items, action.payload];
        }
      })
      .addCase(createAgentsOffering.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeAgentsOfferingBySessionId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAgentsOfferingBySessionId.fulfilled, (state, action) => {
        const index = state.items.findIndex(
          (agent) => agent.sessionUUID === action.payload
        );

        let removeItems = state.items.filter(
          (item) => item.sessionUUID !== action.payload
        );
        state.items = removeItems;
      })
      .addCase(removeAgentsOfferingBySessionId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeAgentsByAgentsId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAgentsByAgentsId.fulfilled, (state, action) => {
        const index = state.items.findIndex(
          (agent) => agent.agent === action.payload
        );

        if (index !== -1) {
            state.items.splice(index, 1);
        } 
      })
      .addCase(removeAgentsByAgentsId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default agentsOfferingSlice.reducer;
