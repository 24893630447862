import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import AvailableUserItem from "./available-user-item";

const AvailableUsersList = ({
  sortBy,
  searchInputAvailableList,
  handleBridgeCall,
  handleTransferCall,
}) => {
  let extensions = useSelector((state) => state.extensions.items.result) || [];
  const [ext, setExt] = useState(extensions || []);

  useEffect(() => {
    if (extensions && extensions.length > 0) {
      setExt(extensions);
    }
  }, [extensions]);

  return (
    <>
      {ext &&
        ext.length > 0 &&
        ext
          .slice()
          .sort((a, b) => {
            const nameA = a?.name.toLowerCase(); // Convert names to lowercase for case-insensitive comparison
            const nameB = b?.name.toLowerCase();
            const extensionA = a?.extension;
            const extensionB = b?.extension;
            if (sortBy == "aToZ") {
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            }
            if (sortBy == "bToZ") {
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              return 0;
            }
            if (sortBy == "extensionAsscending") {
              if (extensionA < extensionB) {
                return -1;
              }
              if (extensionA > extensionB) {
                return 1;
              }
              return 0;
            }
            if (sortBy == "extensionDescending") {
              if (extensionA > extensionB) {
                return -1;
              }
              if (extensionA < extensionB) {
                return 1;
              }
              return 0;
            }
          })
          .filter((item) => {
            if (item) {
              const searchPattern = new RegExp(searchInputAvailableList, "i");
              return (
                searchPattern.test(item?.name) ||
                searchPattern.test(item?.extension)
              );
            }
          })
          .map((item) => {
            return (
              <AvailableUserItem
                extension={item.extension}
                name={item.name}
                key={item.extension}
                id={item.uuid}
                handleTransferCall={handleTransferCall}
                handleBridgeCall={handleBridgeCall}
              />
            );
          })}
    </>
  );
};

export default AvailableUsersList;
