import React from "react";
import ico_ongoing from "images/images/ico-call-ongoing.svg"
import ico_incoming from "images/images/ico-call-incoming.svg"
import ico_outgoing from "images/images/ico-call-outgoing.svg"
import ico_parked from "images/images/ico-call-parked.svg"
import ico_user from "images/images/ico-user.svg"

const CallDetails = ({cloumnData}) => {

  return (
    <div class="py-2.5 px-8 centerContent">
      <div class="flex items-center justify-center">
        <div class="flex items-center py-2.5 px-5">
          <span>
            <img src={ico_ongoing} alt="" />
          </span>
          <span class="text-4xl pl-2.5 pr-2">{cloumnData?.totalCalls}</span>
          <span class="text-xs font-medium">
            ongoing <br /> {cloumnData.totalCalls <= 1 ? "call":"calls"}
          </span>
        </div>
        <div class="flex items-center py-2.5 px-5">
          <span>
            <img src={ico_incoming} alt="" />
          </span>
          <span class="text-4xl pl-2.5 pr-2">{cloumnData?.incoming}</span>
          <span class="text-xs font-medium">
            incoming <br /> {cloumnData.incoming  <= 1 ? "call":"calls" }
          </span>
        </div>
        <div class="flex items-center py-2.5 px-5">
          <span>
            <img src={ico_outgoing} alt="" />
          </span>
          <span class="text-4xl pl-2.5 pr-2">{cloumnData.outgoing}</span>
          <span class="text-xs font-medium">
            outgoing <br /> {cloumnData.outgoing  <= 1 ? "call":"calls" }
          </span>
        </div>
        <div class="flex items-center py-2.5 px-5">
          <span>
            <img src={ico_parked} alt="" />
          </span>
          <span class="text-4xl pl-2.5 pr-2">{cloumnData.parked}</span>
          <span class="text-xs font-medium">
            parked <br /> {cloumnData.parked  <= 1 ? "call":"calls" }
          </span>
        </div>
        <div class="flex items-center py-2.5 px-5">
          <span>
            <img src={ico_user} alt="" />
          </span>
          <span class="text-4xl pl-2.5 pr-2">{cloumnData.totalUsers}</span>
          <span class="text-xs font-medium">
            available <br /> {cloumnData.totalUsers == 1 ? 'User':"Users"}
          </span>
        </div>
      </div>
    </div>
  );
};


export default CallDetails