import { combineReducers } from 'redux'

import { callsSlice } from './callSlice'
import accountSliceReducer from './accountSlice'
import ivrMenus from './ivrMenus'
import ringGroups from './ringGroups'
import extensions from './extensions'
import callDetails from "./callDetailsSlice"
import callCenterDataSlice from './callCenterDataSlice'
import usersDataSlice from "./userSlice"
import agentSlice from './agentSlice'
import activeAgentsSlice from './activeAgentsSlice'
import callCenterCallsSlice from './callCenterCallsSlice'
import activeCallCenterSlice from './activeCallCenterSlice'
import agentsOffering from './agentsOffering'
import googleAuth from './googleAuth'

const rootReducer = combineReducers({
  calls: callsSlice.reducer,
  accounts:accountSliceReducer,
  ivrMenus:ivrMenus,
  ringGroups:ringGroups,
  extensions:extensions,
  callDetails:callDetails,
  callCenterDataSlice:callCenterDataSlice,
  usersDataSlice:usersDataSlice,
  agentsSlice:agentSlice,
  activeAgents:activeAgentsSlice,
  callCenterCallSlice:callCenterCallsSlice,
  activeCallCenterSlice:activeCallCenterSlice,
  agentsOffering:agentsOffering,
  googleAuth:googleAuth,
})

export default rootReducer