import NewCallItem from "pages/dashboard/dashboard-users/call-list/call-item-new";

import { useMemo } from "react";
import { useSelector } from "react-redux";

const useMemoizedCallItems = (callsData, timersHook, display, handleRecord, handleBridgeCall, groupBy, handleTransferCall) => {
  const ivrMenus = useSelector((state) => state.ivrMenus.items);
  const ringGroups = useSelector((state) => state.ringGroups.items);
  const extensions = useSelector((state) => state.extensions.items);
  const callCenterData = useSelector(
    (state) => state.callCenterDataSlice.items
  );

  const memoizedCallItems = useMemo(() => {
    return <>
      {
        groupBy.map(item => {
          if (item.id == "ivrMenu" && item.enabled) {
            return <>
              {
                callsData.filter((item) => ivrMenus?.result?.some((menu) => menu.extension == item.destination)).length > 0 && <span className="font-semibold">
                  IVR
                </span>
              }
              <div className={display == "grid" ? "grid grid-cols-3 gap-2 overflow-auto touch-pan-x" : ""}>
                {callsData.filter((call) => ivrMenus?.result?.some((menu) => menu.extension == call.destination))?.map((item) => {
                  return (
                    <NewCallItem
                      display={display}
                      key={item.callUUID}
                      time={
                        timersHook[item?.callUUID] ? timersHook[item?.callUUID] : "00:00"
                      }
                      phone={item.callerIDNumber}
                      name={item.callerIDName}
                      info={item}
                      status={item.direction}
                      handleRecord={handleRecord}
                      handleBridgeCall={handleBridgeCall}
                      handleTransferCall={handleTransferCall}
                    />
                  );
                })}
              </div>
            </>
          }
          else if (item.id == "ringGroup"  && item.enabled) {
            return <>
              {
                callsData.filter((item) => ringGroups?.result?.some((ring) => ring.extension == item.destination)).length > 0 && <span className="font-semibold">
                  Ring Group
                </span>
              }
              <div className={display == "grid" ? "grid grid-cols-3 gap-2 overflow-auto touch-pan-x" : ""}>

                {callsData.filter((item) => ringGroups?.result?.some((ring) => ring.extension == item.destination)).map((item) => {
                  return (
                    <NewCallItem
                      display={display}
                      key={item.callUUID}
                      time={
                        timersHook[item?.callUUID] ? timersHook[item?.callUUID] : "00:00"
                      }
                      phone={item.callerIDNumber}
                      name={item.callerIDName}
                      info={item}
                      status={item.direction}
                      handleRecord={handleRecord}
                      handleBridgeCall={handleBridgeCall}
                      handleTransferCall={handleTransferCall}

                    />
                  );
                })}
              </div>
            </>
          }
          else if (item.id == "extensions"  && item.enabled) {
            return <>
              {
                callsData.filter((item) => extensions?.result?.some((ext) => ext.extension == item.destination)).length > 0 && <span className="font-semibold">
                  Extensions
                </span>
              }
              <div className={display == "grid" ? "grid grid-cols-3 gap-2 overflow-auto touch-pan-x" : ""}>
                {callsData.filter((item) => extensions?.result?.some((ext) => ext.extension == item.destination)).map((item) => {
                  return (
                    <NewCallItem
                      display={display}
                      key={item.callUUID}
                      time={
                        timersHook[item?.callUUID] ? timersHook[item?.callUUID] : "00:00"
                      }
                      phone={item.callerIDNumber}
                      name={item.callerIDName}
                      info={item}
                      status={item.direction}
                      handleRecord={handleRecord}
                      handleBridgeCall={handleBridgeCall}
                      handleTransferCall={handleTransferCall}

                    />
                  );
                })}
              </div>
            </>
          }
          else if (item.id == "queue"  && item.enabled) {
            return <>
              {
                callsData.filter((item) => callCenterData?.result?.some((ext) => ext.extension == item.destination)).length > 0 && <span className="font-semibold">
                 Queue
                </span>
              }
              <div className={display == "grid" ? "grid grid-cols-3 gap-2 overflow-auto touch-pan-x" : ""}>
                {
                  callsData.filter((item) => callCenterData?.result?.some((ext) => ext.extension == item.destination)).map((item) => {
                    return (
                      <NewCallItem
                        display={display}
                        key={item.callUUID}
                        time={
                          timersHook[item?.callUUID] ? timersHook[item?.callUUID] : "00:00"
                        }
                        phone={item.callerIDNumber}
                        name={item.callerIDName}
                        info={item}
                        status={item.direction}
                        handleRecord={handleRecord}
                        handleBridgeCall={handleBridgeCall}
                        handleTransferCall={handleTransferCall}

                      />
                    );
                  })}
              </div>
            </>
          }
          else if (item.id == "otherCalls"  && item.enabled) {
            return <>
              {callsData.length > 0 &&
                callsData.filter((item) => {
                  return !callCenterData?.result?.some((ext) => ext.extension == item.destination) &&
                    !ivrMenus?.result?.some((menu) => menu.extension == item.destination) &&
                    !ringGroups?.result?.some((ring) => ring.extension == item.destination) &&
                    !extensions?.result?.some((ext) => ext.extension == item.destination)
                }).length > 0 &&
                <span className="font-semibold">Calls</span>
              }
              <div className={display == "grid" ? "grid grid-cols-3 gap-2 overflow-auto touch-pan-x" : ""}>
                {
                  callsData.length > 0 &&
                  callsData.filter((item) => {
                    return !callCenterData?.result?.some((ext) => ext.extension == item.destination) &&
                      !ivrMenus?.result?.some((menu) => menu.extension == item.destination) &&
                      !ringGroups?.result?.some((ring) => ring.extension == item.destination) &&
                      !extensions?.result?.some((ext) => ext.extension == item.destination)
                  }).map((item) => {
                    return (
                      <NewCallItem
                        display={display}
                        key={item.callUUID}
                        time={
                          timersHook[item?.callUUID] ? timersHook[item?.callUUID] : "00:00"
                        }
                        phone={item.callerIDNumber}
                        name={item.callerIDName}
                        info={item}
                        status={item.direction}
                        handleRecord={handleRecord}
                        handleBridgeCall={handleBridgeCall}
                        handleTransferCall={handleTransferCall}

                      />
                    );
                  })
                }
              </div>
            </>
          }
        })
      }

    </>

  }, [callsData, timersHook]);

  return memoizedCallItems;
};

export default useMemoizedCallItems;
