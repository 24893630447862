import React from 'react';

const CallCenterDetailComponent = ({ icon, title, element }) => {
    return (
        <>
            <div className='w-full px-6 flex flex-wrap items-center justify-center border-e'>
                <div className='my-2 inline-block w-[30px] text-center'>
                    <img src={icon} className='w-full' />
                </div>
                <div className='ml-2'>
                    <h4 className='text-lg font-medium mb-2'>{title}</h4>
                    {element}
                    {/* <div className='py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#EBEBEB] text-[#898989]'>
                            4 in Queues
                        </div> */}
                </div>
            </div>
            {/* <div className='w-full px-6 flex flex-wrap items-center justify-center border-e'>
                <div className='my-2 inline-block w-[30px] text-center'>
                    <img src={agentIcon} className='w-full' />
                </div>
                <div className='ml-2'>
                    <h4 className='text-lg font-medium mb-2'>Agents</h4>
                    <div className='py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#EBEBEB] text-[#898989]'>
                        4 in Queues
                    </div>
                </div>
            </div>
            <div className='w-full px-6 flex flex-wrap items-center justify-center'>
                <div className='my-2 inline-block w-[30px] text-center'>
                    <img src={callIcon} className='w-full' />
                </div>
                <div className='ml-2'>
                    <h4 className='text-lg font-medium mb-2'>Calls</h4>
                    <div className='flex flex-wrap gap-[5px]'>
                        <span className='py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#E3FADA] text-[#54CA2B]'>2 Active</span>
                        <span className='py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#FADADA] text-[#CA2B2B]'>4 On Hold</span>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default CallCenterDetailComponent;