export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};


function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
}

export function generateNameSpaceColor(namespace,lightness = 90) {
  const hash = hashCode(namespace);
  const hue = hash % 360; // Use hash code to determine the hue value (0 to 359)
  const color = `hsl(${hue}, 100%, ${lightness}%)`; // Set the lightness component for a lighter color

  return color;
}

