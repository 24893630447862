import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

export const postAccount = createAsyncThunk(
  "accounts/postAccount",
  async (newItem) => {
    let response = {
      data: newItem,
    };
    return response.data;
  }
);

export const updateAccount = createAsyncThunk(
  "accounts/updateAccount",
  async (newItem) => {
    let response = {
      data: newItem,
    };
    return response.data;
  }
);

const accountSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add the async thunk actions to the reducer
    builder
      .addCase(updateAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        const updatedData = action.payload;
        const index = state.items.findIndex(
          (item) => item.userId === updatedData.userId
        );
        if (index !== -1) {
          state.items[index] = updatedData;
          state.status = "succeeded";
        }
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(postAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items.push(action.payload);
      })
      .addCase(postAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default accountSlice.reducer;
