import { api } from "@data-phone/react-resource-server-auth";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


// Create an async thunk to fetch data from the API
export const fetchActiveAgents = createAsyncThunk(
  "items/activeAgents",
  async () => {
    const response = await api.get(`${process.env.REACT_APP_LIVE_CALL_URL}/api/callcenter/active`);
    return response;
  }
);



export const updateAgentStatus = createAsyncThunk(
  "items/updateActiveAgent",
  async (data) => {
    try {
      let object = { ...data };
      let name = object.name.split("@")[0];
      object.agentId = name;
      return object;
    } catch (error) {
      console.log("error", error);
    }
  }
);


export const removeAllQueueMembers = createAsyncThunk(
  "items/removeAllQueueMembers",
  async () => {
    try {
      return true; // We can return true since there's no specific data needed for the deletion
    } catch (error) {
      console.log("error", error);
    }
  }
);
// Create an async thunk to post a new item to the API

// Define the initial state
const initialState = {
  items: {},
  status: "idle",
  error: null,
};

// Define the slice
const activeAgentsSlice = createSlice({
  name: "active",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add the async thunk actions to the reducer
    builder
      .addCase(fetchActiveAgents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchActiveAgents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchActiveAgents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateAgentStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAgentStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        let state2 = {
          ...state,
          items: JSON.parse(JSON.stringify(state.items)),
        };
        const agentIndex = state2.items.result.agents.findIndex(
          (agent) => agent.name === action.payload.agentId
        );

        // Update the agent state if the agent is found
        if (agentIndex !== -1) {
          state2.items.result.agents[agentIndex].state = action.payload.state;
        }
        state.items = { ...state2.items };

        //
      })
      .addCase(updateAgentStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeAllQueueMembers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAllQueueMembers.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Remove the members array from all queues
        state.items.result.queues.forEach((queue) => {
          queue.members = [];
        });
      })
      .addCase(removeAllQueueMembers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default activeAgentsSlice.reducer;
