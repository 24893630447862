import React from "react";

const PageNotFound = () => {
  return (
    <section class="bg-gray-50 dark:bg-gray-900">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <h2 class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          404 Page not found
        </h2>
      </div>
    </section>
  );
};

export default PageNotFound;
