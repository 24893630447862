export const colors ={
  black: '#383838',

  green: '#51A39A',
  darkGreen: '#0C8275',
  lightGreen: '#68B209',
  smoothGreen: '#CBEDA0',

  smoothPurple: '#D8D1FF',

  lightOrange: '#E59D0F',
  smoothOrange: '#F9DCA4',

  lightRed: '#BD0000',
  smoothRed: '#FFD1D1',

  gray: '#B3B3B3',
  darkGrey: '#979797',
  lightGray: '#F4F4F4',
  lightGray2: '#E2E2E2',
  smoothGray: '#E7E7E7',
  smoothGray2: '#F0F2F2',
}
