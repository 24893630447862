import { AdminSidebar } from "components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from "./AdminWrapper.module.scss";

const AdminWrapper = ({ children }) => {
  let user = true

  return (
    <div className={styles.wrapper}>
      {user && <AdminSidebar />}

      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default AdminWrapper;
