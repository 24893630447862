import React, { useState, useEffect } from 'react';

const useCallTimers = (callDetails) => {
  const [timers, setTimers] = useState({});
  const [timeInterval, setTimeInterval] = useState(null);

  useEffect(() => {
    callDetails.forEach((call) => {
      if (call.answerTime) {
        const answerTime = new Date(call.answerTime);
        const timerId = setInterval(() => {
          const diff = Date.now() - answerTime;
          const minutes = Math.floor(diff / 60000);
          const seconds = Math.floor((diff % 60000) / 1000);
          const timerText = `${minutes<10?"0":""}${minutes < 0 ? 0 : minutes}:${
            seconds < 10 ? '0' : ''
          }${seconds < 0 ? 0 : seconds}`;
          setTimers((prevTimers) => ({ ...prevTimers, [call.callUUID]: timerText }));
        }, 1000);
        setTimeInterval(timerId);
        return () => clearInterval(timerId);
      }
    });
    if (callDetails.length === 0) {
      clearInterval(timeInterval);
    }
  }, [callDetails]);

  return timers;
};

export default useCallTimers;