import useCallSocket from "hooks/useCallSocket";
import { useStatusText } from "hooks/useStatusText";
import { useTextAndBorderColor } from "hooks/useTextAndBorderColor";
import React from "react";
import { useDrop,useDrag } from "react-dnd";

const AvailableUserItem = ({ extension, name, id, handleBridgeCall ,handleTransferCall}) => {
  const { statusText } = useStatusText(extension);
  const { textColor, borderColor, backgroundColor } =
    useTextAndBorderColor(statusText);

  const [, drop] = useDrop({
    accept: "call-card",
    drop: (item) => {
      if (item.uuid !== "" && id !== "") {
        handleTransferCall(item.uuid, extension);
      }
    },
  });
  const [, drag] = useDrag({
    type: "agent-card",
    item: { type: "agent-card", extension: extension},
  });

  return (
    <div ref={drop}  class="card py-2 px-4 borderRound mt-2 rounded-lg">
      <div ref={drag} class="flex items-center justify-between">
        <div className="flex items-center">
          <div class="counter mx-1 text-xs font-normal py-1 px-2 rounded">
            {extension}
          </div>
          <span class="text-xs font-medium">{name}</span>
        </div>
        <div>
          <span style={{ color: textColor }} class="text-xs font-medium">
            {statusText}
          </span>
        </div>
      </div>
    </div>
  );
};
export default AvailableUserItem;
