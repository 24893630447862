import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addData,
  removeData,
  updateData,
} from "redux/slices/callDetailsSlice";
import { useSocket } from "@data-phone/react-resource-server-auth";


const useCallSocket = () => {
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const ivrMenus = useSelector((state) => state.ivrMenus.items);
  const ringGroups = useSelector((state) => state.ringGroups.items);
  const extensions = useSelector((state) => state.extensions.items);

  const { socket } = useSocket({
    socketUrl: `${process.env.REACT_APP_LIVE_CALL_WSS}/activeCalls`,
    path: ``,
  })


  const handleTransferCall = (uuid1, extension) => {
    if (socketRef.current) {
      socketRef.current.emit("transfer", uuid1, extension, (err) => {
        if (err) {
          console.error("Error while performing transfer action:", err);
        }
      });
    }
  };

  const handleRecord = (data,action) => {
    if (socketRef.current) {
      socketRef.current.emit("record", data, action, (err) => {
        if (err) {
          console.error("Error while performing record action:", err);
        }
      });
    }
  };

  const handleBridgeCall = (uuid1, uuid2) => {
    if (socketRef.current) {
      socketRef.current.emit("bridge", uuid1, uuid2, (err) => {
        if (err) {
          console.error("Error while performing bridge action:", err);
        }
      });
    }
  };

  useEffect(() => {
    if (socket) {
      socket.emit("get-calls");
      if (socket.connected) {
        console.log("client connected call");
      }
      socket.on("get-calls", (data) => {
        let requiredData = [];
        data.forEach((call) => {
          // outgoing
          if (
            extensions?.result?.some(
              (item) => item.extension == call.callerIDNumber
            ) ||
            ivrMenus?.result?.some(
              (item) => item.extension == call.callerIDNumber
            ) ||
            ringGroups?.result?.some(
              (item) => item.extension == call.callerIDNumber
            )
          ) {
            call.isOutgoing = true;
            requiredData.push(call);
          } else {
            if (call.callees.length > 0) {
              if (call.callees.some((item) => item.answerState == "answered")) {
                let index = call.callees.findIndex(
                  (item) => item.answerState == "answered"
                );
                if (index !== -1) {
                  call.answerState = "answered";
                  call.destination = call.callees[index].number;
                }
                requiredData.push(call);
              } else {
                requiredData.push(call);
              }
            } else {
              requiredData.push(call);
            }
          }
        });
        dispatch(addData(requiredData));
      });

      socket.on("connect_error", async (err) => {
        console.log("connection err", err);
        console.error(err.message);
      });

      socket.on("disconnect", () => {
        console.log("client disconnected", new Date());
      });

      socket.on("call-destroy", (data) => {
        dispatch(removeData(data));
      });
      socket.on("call-update", (data) => {
        // outgoing
        if (
          extensions?.result?.some(
            (item) => item.extension == data.callerIDNumber
          ) ||
          ivrMenus?.result?.some(
            (item) => item.extension == data.callerIDNumber
          ) ||
          ringGroups?.result?.some(
            (item) => item.extension == data.callerIDNumber
          )
        ) {
          data.isOutgoing = true;
          dispatch(updateData(data));
        } else {
          //incoming
          if (data.callees.length > 0) {
            if (data.callees.some((item) => item.answerState == "answered")) {
              let index = data.callees.findIndex(
                (item) => item.answerState == "answered"
              );
              if (index !== -1) {
                data.answerState = "answered";
                data.destination = data.callees[index].number;
              }
              dispatch(updateData(data));
            } else {
              dispatch(updateData(data));
            }
          } else {
            dispatch(updateData(data));
          }
        }

        console.log("call-update", data);
      });

      socket.on("auth-failure", (data) => {
        console.log("auth-failure");
        console.log(data);
      });

      socket.on("close", () => {
        console.log("client closed");
      });

      socketRef.current = socket;

    }

    return () => {
      if(socketRef.current) {
        socket.disconnect();
        socketRef.current = null;
      }
    };
  }, [socket]);

  return { handleRecord: handleRecord, handleBridgeCall: handleBridgeCall ,handleTransferCall};
};

export default useCallSocket;
