import { createSlice } from "@reduxjs/toolkit";



// Create an async thunk to post a new item to the API

// Define the initial state
const initialState = {
  data: {},
};

// Define the slice
const usersDataSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    }
  },

});


export const { setUser } = usersDataSlice.actions;
// Export the async thunks and the reducer
// export { fetchItems, postItem };
export default usersDataSlice.reducer;
