import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { api } from "@data-phone/react-resource-server-auth";

export const fetchAgents = createAsyncThunk("items/agents", async () => {
  const response = await api.get(`${process.env.REACT_APP_LIVE_CALL_URL}/api/callcenter/agents`);
  return response;
});



// Create an async thunk to post a new item to the API

// Define the initial state
const initialState = {
  items: [],
  status: "idle",
  error: null,
};

// Define the slice
const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add the async thunk actions to the reducer
    builder
      .addCase(fetchAgents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAgents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchAgents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

  },
});


export default agentsSlice.reducer;
