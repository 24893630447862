import React, { useEffect, useState } from "react";

import CallCenterDetailComponent from "components/callCenterDetails/CallCenterDetailComponent";
import useCallCenterSocket from "hooks/useCallCenterSocket";
import { useDispatch, useSelector } from "react-redux";

import { updateCallCenterCalls } from "redux/slices/callCenterCallsSlice";

import activeCallCenterSlice, {
  removeAllAactiveCallCennterData,
  updateActiveCallsCallCenter,
} from "redux/slices/activeCallCenterSlice";

import {
  fetchActiveAgents,
  removeAllQueueMembers,
} from "redux/slices/activeAgentsSlice";

import { generateNameSpaceColor } from "helper";

import { createAgentsOffering } from "redux/slices/agentsOffering";

import useCallCentersTimer from "hooks/useCallCenterTimer";

import DepartmentCard from "components/callCenterDetails/DepartmentCard";

import AgentsCard from "components/callCenterDetails/AgentsCard";


import { fetchAgents } from "redux/slices/agentSlice";

import { fetchCallCenterData } from "redux/slices/callCenterDataSlice";

import departmentIcon from "../images/images/department-icon.svg";

import agentIcon from "../images/images/agent-icon.svg";

import callIcon from "../images/images/call-icon.svg";

import greenIcoTimer from "../images/images/ico-timer.svg";

import redIcoTimer from "../images/images/red-ico-timer.svg";


import ico_search from "../images/images/ico-search.svg";


const CallCenter = () => {
  const [tab, setActiveTab] = useState("department");

  const [selectedDepartment, setSelectedDepartment] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useCallCenterSocket();
  // "4ded7ead-018c-4e3b-b8a1-9c279ea5d556"

  const agentsData = useSelector((state) => state.agentsSlice.items);
  const callCenterCallsData = useSelector(
    (state) => state.callCenterCallSlice.items
  );
  const queues = useSelector((state) => state.callCenterDataSlice.items.result);
  const activecallCenterSlice = useSelector(
    (state) => state.activeCallCenterSlice.items
  );

  const activeCallsInitial =
    useSelector((state) => state.activeAgents.items) || {};

  const [QueueItems, setQueueItems] = useState([]);
  const [activeCalls, setActiveCalls] = useState([]);

  const timersHook = useCallCentersTimer(activecallCenterSlice);
  const queueTimersHook = useCallCentersTimer(callCenterCallsData);
  const agentsOffering = useSelector((state) => state.agentsOffering.items);
  const agents = useSelector((state) => state.agentsSlice.items.result);
  const [searchInput, setSearchInput] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActiveAgents())
    dispatch(fetchAgents())
    dispatch(fetchCallCenterData())
  }, [])


  // agents-offering-for-initial-API
  useEffect(() => {
    if (activeCallsInitial.result) {
      if (activeCallsInitial.result.queues &&
        activeCallsInitial.result.queues.length > 0
      ) {
        activeCallsInitial.result.queues.forEach(item => {
          if (item.members && item.members.length > 0) {
            item.members.forEach(member => {
              if (member.state == "Trying") {
                if (item.tiers && item.tiers.length > 0) {
                  item.tiers.forEach(tier => {
                    if (tier.state == "Offering") {
                      let agentOffering = {
                        uuid: member?.uuid,
                        sessionUUID: member?.sessionUUID,
                        queue: item?.name,
                        callerIDName: member?.callerIDName,
                        callerIDNumber: member?.callerIDNumber,
                        event: "agent-offering",
                        domain: "",
                        agent: `${tier?.agent}`
                      }
                      dispatch(createAgentsOffering(agentOffering))
                    }
                  })

                }
              }
            })
          }
        })
      }
    }
  }, [activeCallsInitial])

  useEffect(() => {
    if (activeCallsInitial.result) {
      if (
        activeCallsInitial.result.queues &&
        activeCallsInitial.result.queues.length > 0
      ) {

        activeCallsInitial.result.queues.forEach((item) => {
          // dispatch(removeAllAactiveCallCennterData());
          if (item.members && item.members.length > 0) {
            item.members.forEach((member) => {
              let obj = {
                agentName: "",
                // memberSessionUUID: member.sessionUUID,
                memberCIDName: member.callerIDName,
                memberCIDNumber: member.callerIDNumber,
                calledTime: member.joinedEpoch,
                joinedEpoch: member.joinedEpoch,
                queueName: item.name,
              };

              if (member.state == "Trying") {
                obj.sessionUUID = member.sessionUUID;
                obj.queue = item.name;
                dispatch(removeAllQueueMembers());
                dispatch(updateCallCenterCalls(obj));
              } else if (member.state == "Answered") {
                if (activecallCenterSlice.length > 0) {
                  if (
                    !activecallCenterSlice.some((item) => {
                      return item.memberSessionUUID == member.sessionUUID;
                    })
                  ) {
                    obj.name = member.agent;
                    obj.memberSessionUUID = member.sessionUUID;
                    dispatch(removeAllQueueMembers());
                    dispatch(updateActiveCallsCallCenter(obj));
                  }
                } else {
                  obj.name = member.agent;
                  obj.memberSessionUUID = member.sessionUUID;
                  dispatch(removeAllQueueMembers());
                  dispatch(updateActiveCallsCallCenter(obj));
                }
              }
            });
          }
        });
      }
    }
  }, [activeCallsInitial]);

  useEffect(() => {
    if (queues && callCenterCallsData) {
      let finalQueues = [];
      callCenterCallsData.forEach((element) => {
        if (element.queue) {
          queues.forEach((data) => {
            if (element.queue.split("@")[0] == data.extension) {

              let color = generateNameSpaceColor(data.name)
              let obj = {
                ...element,
                queueName: data.name,
                departColor: color,
              };
              finalQueues.push(obj);
              // setfinalQueuesment((prev) => [...prev, obj]);
            }
          });
        } else if (element.queueName) {
          queues.forEach((data) => {
            if (element.queueName == data.extension) {
              let color = generateNameSpaceColor(data.name)

              let obj = {
                ...element,
                queueName: data.name,
                departColor: color,
              };
              finalQueues.push(obj);
              // setfinalQueuesment((prev) => [...prev, obj]);
            }
          });
        }
      });
      setQueueItems(finalQueues);
    }
  }, [callCenterCallsData, queues]);

  useEffect(() => {
    if (queues && activecallCenterSlice) {
      let finalActiveCalls = [];
      activecallCenterSlice?.forEach((element) => {
        if (element.queue) {
          queues.forEach((data) => {
            if (element.queue.split("@")[0] == data.extension) {
              let color = generateNameSpaceColor(data.name)

              let obj = {
                ...element,
                queueName: data.name,
                departColor: color,
              };
              finalActiveCalls.push(obj);
              // setfinalQueuesment((prev) => [...prev, obj]);
            }
          });
        } else if (element.queueName) {
          queues.forEach((data) => {
            if (element.queueName == data.extension) {
              let color = generateNameSpaceColor(data.name)

              let obj = {
                ...element,
                queueName: data.name,
                departColor: color,
              };
              finalActiveCalls.push(obj);
              // setfinalQueuesment((prev) => [...prev, obj]);
            }
          });
        }
      });
      setActiveCalls(finalActiveCalls);
    }
  }, [activecallCenterSlice, queues]);

  return (
    <section className="bg-[#F9FBFC] md:h-full h-auto">
      <div className="grid grid-cols-12 h-full">
        <div className="col-span-12 xxl:col-span-9 lg:col-span-8">
          <div class="px-7 py-4">
            <h1 class="text-2xl font-medium">Call Center</h1>
          </div>
          <div className="border-y p-3">
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-2 gap-6  w-full mx-auto">
              <CallCenterDetailComponent
                icon={departmentIcon}
                title="Department"
                element={
                  <div className="py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#EBEBEB] text-[#898989]">
                    {selectedDepartment && selectedDepartment.length > 0
                      ? selectedDepartment.length
                      : activeCallsInitial &&
                      activeCallsInitial.result &&
                      activeCallsInitial.result.queues.length > 0 &&
                      activeCallsInitial.result.queues.length}
                    /{" "}
                    {activeCallsInitial &&
                      activeCallsInitial.result &&
                      activeCallsInitial.result.queues.length > 0 &&
                      activeCallsInitial.result.queues.length}
                  </div>
                }
              />
              <CallCenterDetailComponent
                icon={agentIcon}
                title="Agents"
                element={
                  <div>
                    <div className="py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#E3FADA] text-[#54CA2B]">
                      {activeCallsInitial &&
                        activeCallsInitial.result &&
                        activeCallsInitial.result.agents.length > 0 &&
                        activeCallsInitial.result.agents.filter((agent) => {
                          if (selectedDepartment.length === 0) {
                            const isInSelectedDepartment =
                              activeCallsInitial.result.queues.some((queue) => {
                                return queue?.tiers?.some((tier) => {
                                  return (
                                    tier.agent === agent.name &&
                                    agent.state === "In a queue call"
                                  );
                                });
                              });
                            return isInSelectedDepartment;
                            // Include all agents when no department is selected
                          } else {
                            // Check if the agent is in a queue call for a selected department
                            const isInSelectedDepartment =
                              activeCallsInitial.result.queues.some((queue) => {
                                return queue?.tiers?.some((tier) => {
                                  return (
                                    tier.agent === agent.name &&
                                    agent.state === "In a queue call" &&
                                    selectedDepartment.includes(queue.name)
                                  );
                                });
                              });
                            return isInSelectedDepartment;
                          }
                        }).length}{" "}
                      Answered calls
                    </div>

                    <div className="py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#EBEBEB] text-[#898989] ml-2">
                      {activeCallsInitial &&
                        activeCallsInitial.result &&
                        activeCallsInitial.result.agents.length > 0 &&
                        activeCallsInitial.result.agents.filter((agent) => {
                          if (selectedDepartment.length === 0) {
                            const isInSelectedDepartment =
                              activeCallsInitial.result.queues.some((queue) => {
                                return queue?.tiers?.some((tier) => {
                                  return (
                                    tier.agent === agent.name &&
                                    agent.state === "Waiting"
                                  );
                                });
                              });
                            return isInSelectedDepartment;
                            // Include all agents when no department is selected
                          } else {
                            // Check if the agent is in a queue call for a selected department
                            const isInSelectedDepartment =
                              activeCallsInitial.result.queues.some((queue) => {
                                return queue?.tiers?.some((tier) => {
                                  return (
                                    tier.agent === agent.name &&
                                    agent.state === "Waiting" &&
                                    selectedDepartment.includes(queue.name)
                                  );
                                });
                              });
                            return isInSelectedDepartment;
                          }
                        }).length}{" "}
                      Waiting
                    </div>
                  </div>
                }
              />
              <CallCenterDetailComponent
                icon={callIcon}
                title="Call"
                element={
                  <div className="flex flex-wrap gap-[5px]">
                    <span className="py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#E3FADA] text-[#54CA2B]">
                      {activeCalls.filter((item) => {
                        if (selectedDepartment.length == 0) {
                          return item;
                        } else {
                          if (item.queue) {
                            if (
                              selectedDepartment.includes(
                                item.queue.split("@")[0]
                              )
                            ) {
                              return item;
                            }
                          }
                        }
                      }).length > 0
                        ? activeCalls.filter((item) => {
                          if (selectedDepartment.length == 0) {
                            return item;
                          } else {
                            if (item.queue) {
                              if (
                                selectedDepartment.includes(
                                  item.queue.split("@")[0]
                                )
                              ) {
                                return item;
                              }
                            }
                          }
                        }).length
                        : 0}{" "}
                      Answered calls
                    </span>
                    <span className="py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#FADADA] text-[#CA2B2B]">
                      {QueueItems.filter((item) => {
                        if (selectedDepartment.length == 0) {
                          return item;
                        } else {
                          if (item.queue) {
                            if (
                              selectedDepartment.includes(
                                item.queue.split("@")[0]
                              )
                            ) {
                              return item;
                            }
                          }
                        }
                      }).length > 0
                        ? QueueItems.filter((item) => {
                          if (selectedDepartment.length == 0) {
                            return item;
                          } else {
                            if (item.queue) {
                              if (
                                selectedDepartment.includes(
                                  item.queue.split("@")[0]
                                )
                              ) {
                                return item;
                              }
                            }
                          }
                        }).length
                        : 0}{" "}
                      Waiting
                    </span>
                  </div>
                }
              />
            </div>
          </div>
          <div className="input-group flex mx-5 pt-3 relative items-center">
            <button
              type="button"
              className="p-0 absolute left-0 top-auto ml-2"
              style={{ width: "20px", height: "20px" }}
            >
              <img src={ico_search} alt="" className="w-full" />
            </button>
            <input
              type="text"
              placeholder="Search"
              className="focus:outline-none text-xs font-medium md:w-2/4 lg:w-80 w-full py-2 pl-30 bg-white rounded borderRound"
              style={{ paddingLeft: "40px" }}
              onChange={(event) => {
                setSearchInput(event.target.value);
              }}
              value={searchInput}
            />
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 px-5 py-6 gap-[22px]">
            <div className="w-full border border-[#EBECEC] bg-white px-2 py-3 rounded-[8px] flex flex-wrap flex-col gap-[10px]">
              <h5 className="text-sm font-medium text-[#000000]">
                Active Calls -{" "}
                {activeCalls.filter((item) => {
                  if (selectedDepartment.length == 0) {
                    return item;
                  } else {
                    if (item.queue) {
                      if (
                        selectedDepartment.includes(item.queue.split("@")[0])
                      ) {
                        return item;
                      }
                    }
                  }
                }).length > 0
                  ? activeCalls.filter((item) => {
                    if (selectedDepartment.length == 0) {
                      return item;
                    } else {
                      if (item.queue) {
                        if (
                          selectedDepartment.includes(
                            item.queue.split("@")[0]
                          )
                        ) {
                          return item;
                        }
                      }
                    }
                  }).length
                  : 0}
              </h5>
              {activeCalls
                .filter((item) => {
                  if (selectedDepartment.length == 0) {
                    return item;
                  } else {
                    if (item.queue) {
                      if (
                        selectedDepartment.includes(item.queue.split("@")[0])
                      ) {
                        return item;
                      }
                    }
                  }
                })
                .filter((item) => {
                  if (item) {
                    const searchPattern = new RegExp(searchInput, "i");
                    return (
                      searchPattern.test(item?.memberCIDName) ||
                      searchPattern.test(item?.memberCIDNumber)
                    );
                  }
                })
                .sort((a, b) => {
                  return parseInt(a.calledTime) - parseInt(b.calledTime);
                })
                .map((item, index) => {
                  let agentsExt = ""
                  agents.forEach((info) => {
                    if (info.name == item.name.split("@")[0]) {
                      agentsExt = info.extension
                    }
                  })
                  return (
                    <div
                      className="w-full grid lg:gap-3 gap-2 xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-2 grid-cols-1 border rounded-[8px] p-2"
                      style={{
                        borderColor: item.departColor,
                        borderWidth: "3px",
                        backgroundColor: item.departColor,
                      }}
                    >
                      <div className="text-left">
                        <h5 className="text-sm font-semibold text-[#3EAF17]">
                          {item.memberCIDNumber}
                        </h5>
                        <div className="py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#EBEBEB]">
                          {item.memberCIDName}
                        </div>
                        <span className="text-xs font-medium ">
                          {`is on call with `}
                          <span className=" py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#EBEBEB]">
                            {agentsExt}
                          </span>
                        </span>
                      </div>
                      <div className="text-right">
                        <div className="bg-[#E3FADA] py-1 px-2 text-xs font-medium rounded-[4px] text-[#59CA31] inline-flex gap-[2px] items-center justify-center">
                          <img src={greenIcoTimer} width={20} height={20} />
                          {/* 01 : 12 */}
                          {timersHook[item?.memberSessionUUID]
                            ? timersHook[item?.memberSessionUUID]
                            : "00:00"}
                          {/* {timersHook[item.memberSessionUUID]} */}
                        </div>
                        <h5
                          className="text-sm font-semibold"
                        >
                          {item.queueName}
                        </h5>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="w-full border border-[#EBECEC] bg-white px-2 py-3 rounded-[8px] flex flex-wrap flex-col gap-[10px]">
              <h5 className="text-sm font-medium text-[#000000]">
                On Hold -{" "}
                {QueueItems.filter((item) => {
                  if (selectedDepartment.length == 0) {
                    return item;
                  } else {
                    if (item.queue) {
                      if (
                        selectedDepartment.includes(item.queue.split("@")[0])
                      ) {
                        return item;
                      }
                    }
                  }
                }).length > 0
                  ? QueueItems.filter((item) => {
                    if (selectedDepartment.length == 0) {
                      return item;
                    } else {
                      if (item.queue) {
                        if (
                          selectedDepartment.includes(
                            item.queue.split("@")[0]
                          )
                        ) {
                          return item;
                        }
                      }
                    }
                  }).length
                  : 0}
              </h5>
              {QueueItems.filter((item) => {
                if (selectedDepartment.length == 0) {
                  return item;
                } else {
                  if (item.queue) {
                    if (selectedDepartment.includes(item.queue.split("@")[0])) {
                      return item;
                    }
                  } else if (item.queueName) {
                    if (selectedDepartment.includes(item.queueName)) {
                      return item;
                    }
                  }
                }
              })
                .filter((item) => {
                  if (item) {
                    const searchPattern = new RegExp(searchInput, "i");
                    return (
                      searchPattern.test(item?.memberCIDName) ||
                      searchPattern.test(item?.memberCIDNumber) ||
                      searchPattern.test(item?.callerIDName) ||
                      searchPattern.test(item?.callerIDNumber)
                    );
                  }
                })
                .sort((a, b) => {
                  return parseInt(a.joinedEpoch) - parseInt(b.joinedEpoch);
                })
                .map((item, index) => {
                  let agentsExt = [];
                  if (agentsOffering && agentsOffering.length > 0) {
                    agents.forEach((info) => {
                      agentsOffering.forEach((agent) => {
                        if (agent.sessionUUID == item.sessionUUID) {
                          if (agent.agent.split("@")[0] == info.name) {
                            if (!agentsExt.includes(info.extension)) {
                              agentsExt.push(info.extension);
                            }
                          }
                        }
                      });
                    });
                  }

                  return (
                    <div
                      className="w-full grid lg:gap-3 gap-2 xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-2 grid-cols-1 border rounded-[8px] p-2"
                      style={{
                        borderColor: item.departColor,
                        backgroundColor: item.departColor,
                        borderWidth: "3px",
                      }}
                    >
                      <div className="text-left">
                        <h5 className="text-sm font-semibold">
                          {item.callerIDNumber || item.memberCIDNumber}
                        </h5>
                        <div>
                          <div className="py-1 px-2 text-xs font-medium inline-block rounded-full bg-[#EBEBEB] ">
                            {item.callerIDName || item.memberCIDName}
                          </div>
                          {agentsExt.length > 0 && (
                            <span className="text-xs font-medium ">
                              {" "}
                              Ringing to{" "}
                            </span>
                          )}
                          {agentsExt.length > 0 &&
                            agentsExt.map((item) => {
                              return (
                                <span className="text-xs font-medium ">
                                  {item}{" "}
                                </span>
                              );
                            })}
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="py-1 px-2 text-xs font-medium rounded-[4px] text-[#CA2B2B] bg-[#ca2b2b1f] inline-flex gap-[2px] items-center justify-center">
                          <img src={redIcoTimer} width={20} height={20} />
                          {/* 01 : 12 */}
                          {queueTimersHook[item?.sessionUUID]
                            ? queueTimersHook[item?.sessionUUID]
                            : "00:00"}
                        </div>
                        <h5
                          className="text-sm font-semibold text-[#898989]"
                        >
                          {item.queueName}
                        </h5>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="col-span-12 xxl:col-span-3 lg:col-span-4 border-s border-[#EBECEC] bg-white px-7 py-4 overflow-auto touch-pan-x">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-[8px] items-center mb-3">
            <button
              className={`btn py-2 px-3 rounded-full text-sm font-medium ${tab == "department"
                ? "bg-[#12A9FE] text-[#FFF]"
                : "bg-[#EEEEEE] text-[#3F3F3F]"
                }`}
              onClick={() => {
                handleTabClick("department");
              }}
            >
              Departments
            </button>
            <button
              className={`btn py-2 px-3 rounded-full text-sm font-medium ${tab == "agents"
                ? "bg-[#12A9FE] text-[#FFF]"
                : "bg-[#EEEEEE] text-[#3F3F3F]"
                }`}
              onClick={() => {
                handleTabClick("agents");
              }}
            >
              Agents
            </button>
          </div>
          <div className="tab-content">
            {tab == "department" && (
              <div className="department-content flex flex-wrap gap-[8px]">
                <DepartmentCard
                  selectedDepartment={selectedDepartment}
                  setSelectedDepartment={setSelectedDepartment}
                />
              </div>
            )}
            {tab == "agents" && (
              <div className="agents-content flex flex-wrap gap-[8px] overflow-auto touch-pan-x">
                <AgentsCard selectedDepartment={selectedDepartment} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallCenter;
