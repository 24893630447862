import { useInfoStatus } from "hooks/useInfoStatus";
import React, { useEffect, useState } from "react";

import useCallStateBorderColor from "hooks/useBorderColor";

import ico_outgoing from "../../../../../images/images/ico-call-outgoing.svg";

import ico_incoming from "../../../../../images/images/ico-call-incoming.svg";

import timerImg from "../../../../../images/images/ico-timer.svg";

import callOnHoldIcon from "../../../../../images/icon/callonhold.png";

import { useDrag, useDrop } from "react-dnd";

const NewCallItem = ({
  time,
  phone,
  name,
  info,
  status,
  display,
  handleRecord,
  handleBridgeCall,
  handleTransferCall,
}) => {
  const { infoText, statusText } = useInfoStatus(info);
  const borderColor = useCallStateBorderColor(info);


  const [, drag] = useDrag({
    type: "call-card",
    canDrag:info.answerState == "ringing" || info.isHolding,
    item: { type: "call-card", uuid: info.callUUID },
  });

  const [, drop] = useDrop({
    accept: "agent-card",
    drop: (item) => {

      if (item.extension !== "" && info.callUUID !== "" && info.answerState == "ringing" || info.isHolding) {
        handleTransferCall( info.callUUID, item.extension);
      }
    },
  });

 
  return display == "grid" ? (
    <div ref={drop}>
    <div 
    ref={drag}
      style={{ border: `2px solid ${borderColor}` }}
      className="card animate-card  py-2 px-3 borderRound rounded-lg bg-white relative"
    >
      <div className="flex items-center">
        <div className="counter mx-1 text-xs font-normal py-1 px-2 rounded">
          {phone}
        </div>
        <span className="text-xs font-medium">{name}</span>
      </div>
      <div className="UsersText flex items-center pt-1">
        <span>
          <img
            className="w-6"
            src={statusText == "Outbound" ? ico_outgoing : ico_incoming}
            alt=""
          />
        </span>
        <p className="text-xs">
          <span className="pl-1.5">{infoText}</span>
          {/* <span className="font-medium pl-1.5">Demo2_20</span> */}
        </p>
        {(info.isHolding || info.isParked) && (
          <span className="ml-1">
            {" "}
            <img
              style={{ height: "40px", width: "40px" }}
              src={callOnHoldIcon}
              alt=""
            />
          </span>
        )}
          {info?.isRecording && (
            <span style={{ marginLeft: "3px" }} className="fade-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                fill="rgb(189, 0, 0)"
                onClick={() => handleRecord(info.callUUID,'stop')}
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192zm0 224a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
              </svg>
            </span>
          )}
          {!info?.isRecording && (
            <span style={{ marginLeft: "3px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                fill="rgb(189, 0, 0)"
                onClick={() => handleRecord(info.callUUID,'start')}
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192zm0 224a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
              </svg>
            </span>
          )}
        {info.answerState == "answered" && (
          <span className="ml-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
              className="mr-2"
            >
              <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
            </svg>
          </span>
        )}
        {info.answerState == "ringing" && (
          <span className="ml-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
              className="mr-2"
            >
              <path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z" />
            </svg>
          </span>
        )}
      </div>
      <div className="timer flex items-center place-content-between py-1 px-2 rounded text-xs font-medium absolute top-0 right-0 absolute top-0 right-0 absolute top-0 right-0">
        <span>
          {" "}
          <img src={timerImg} alt="" />
        </span>
        <span className="pl-1">{time}</span>
      </div>
    </div>
    </div>
  ) : (
    <>
    <div ref={drop}>
      <div
        ref={drag}
        style={{ border: `2px solid ${borderColor}` }}
        className="card listRow-view py-5 flex items-center place-content-between borderRound  bg-white rounded pl-5 pr-11 mb-2"
        // onClick={() => handleRecord(info.callUUID)}
      >
        <div className="flex items-center" >
          <div className="icon">
            <img
              src={statusText == "Outbound" ? ico_outgoing : ico_incoming}
              alt=""
            />
          </div>
          <div className="counter mx-1 text-xs font-normal py-1 px-2 rounded">
            {phone}
          </div>
          <div>
            <p className="text-xs">
              <span className="font-semibold pr-1">{name}</span>
              {/* is on call */}
              {/* with <span className="font-semibold">Demo2_20</span> */}
            </p>
          </div>

          <div>
            <p className="text-xs">
              {infoText} &nbsp;
              {info.isOutgoing !== true ? (
                info.callees.some((item) => item.answerState == "answered") ? (
                  <></>
                ) : (
                  <span className="font-semibold">
                    {" "}
                    {Array.from(
                      new Map(
                        info.callees
                          .filter((item) => item.answerState === "ringing")
                          .map((item) => [item.number, item])
                      ).values()
                    ).map((item) => {
                      return `(${item.number}) `;
                    })}
                  </span>
                )
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {info?.isRecording && (
            <span style={{ marginRight: "3px" }} className="fade-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                fill="rgb(189, 0, 0)"
                onClick={() => handleRecord(info.callUUID,'stop')}
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192zm0 224a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
              </svg>
            </span>
          )}
          {!info?.isRecording && (
            <span style={{ marginRight: "3px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                fill="rgb(189, 0, 0)"
                onClick={() => handleRecord(info.callUUID,'start')}
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192zm0 224a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
              </svg>
            </span>
          )}
          {info.answerState == "answered" && (
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                className="mr-2"
              >
                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
              </svg>
            </span>
          )}
          {!info.answerState == "answered" && (
            <span>
              <span className="invisible">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="mr-2"
                ></svg>
              </span>
            </span>
          )}
          {info.answerState == "ringing" && (
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                className="mr-2"
              >
                <path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z" />
              </svg>
            </span>
          )}
          {info.answerState !== "ringing" && (
            <span>
              <span className="invisible">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="mr-2"
                ></svg>
              </span>
            </span>
          )}

          {info.isHolding || info.isParked ? (
            <span>
              {" "}
              <img
                style={{ height: "40px", width: "40px" }}
                src={callOnHoldIcon}
                alt=""
              />
            </span>
          ) : (
            <span>
              <div style={{ height: "40px", width: "40px" }}></div>
            </span>
          )}

          <div className="timer flex items-center place-content-between py-1 px-2 rounded text-xs font-medium">
            <span>
              {" "}
              <img src={timerImg} alt="" />
            </span>
            <span className="pl-1">{time}</span>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default NewCallItem;
