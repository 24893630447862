import { useSocket } from "@data-phone/react-resource-server-auth";
import axios from "axios";
import cogoToast from "cogo-toast";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchActiveAgents, updateAgentStatus } from "redux/slices/activeAgentsSlice";
import {
  removeActiveCallsCallCenter,
  removeAllAactiveCallCennterData,
  updateActiveCallsCallCenter,
} from "redux/slices/activeCallCenterSlice";
import {
  createAgentsOffering,
  removeAgentsByAgentsId,
  removeAgentsOfferingBySessionId,
} from "redux/slices/agentsOffering";
import {
  removeAllCallCenterHoldCalls,
  removeCallCenterCallByAgentId,
  updateCallCenterCalls,
} from "redux/slices/callCenterCallsSlice";
const useCallCenterSocket = () => {
  const dispatch = useDispatch();

  const { socket } = useSocket({
    socketUrl: `${process.env.REACT_APP_LIVE_CALL_WSS}/callCenter`,
    path: ``,
  })

  const socketRef = useRef(null);

  useEffect(() => {
    if(socket){
      if(socket.connected){
        console.log("client connected");
      }
      socket.on("auth-failure", (data) => {
        console.log(data);
      });

      socket.on("connect_error", (err) => {
   
        console.log(err.message);
      });

      socket.on("disconnect", (reason) => {
        console.log("client disconnected", new Date());
        dispatch(removeAllAactiveCallCennterData())
        dispatch(removeAllCallCenterHoldCalls())
      });

      socket.on("get-queue-list", (data) => {
        console.log("get-queue-list", data);
      });

      socket.on("get-agent-list", (data) => {
        console.log("get-agent-list", data);
      });


      socket.on("callcenter-update", (data) => {
        console.log("callcenter-update", data);
        if (
          data.event == "member-queue-resume" ||
          data.event == "member-queue-start"
        ) {
          dispatch(updateCallCenterCalls(data));
        }
        if (data.event == "agent-state-change") {
          if (data.state == "Waiting") {
            dispatch(removeAgentsByAgentsId(data.name));
          }
          dispatch(updateAgentStatus(data));
        }
        if (data.event == "bridge-agent-start") {
          dispatch(updateActiveCallsCallCenter(data));
          dispatch(removeCallCenterCallByAgentId(data.memberSessionUUID));
          dispatch(removeAgentsOfferingBySessionId(data.sessionUUID));
        }
      
        if (data.event == "member-queue-end") {
          dispatch(removeCallCenterCallByAgentId(data.sessionUUID));
          dispatch(removeActiveCallsCallCenter(data.sessionUUID));
          dispatch(removeAgentsOfferingBySessionId(data.sessionUUID));
        }
        if (data.event == "bridge-agent-end") {
          dispatch(removeActiveCallsCallCenter(data.memberSessionUUID));
          dispatch(removeAgentsOfferingBySessionId(data.sessionUUID));
        }
        if (data.event == "agent-offering") {
          dispatch(createAgentsOffering(data));
        }
      });
      socket.on("auth-failure", (data) => {
        console.log("auth-failure");

        console.log(data);


      });

      socketRef.current = socket;
    }
      return () => {
        if(socket){
          socket.disconnect();
          socketRef.current = null;
        }
      };
  }, [socket]);
};

export default useCallCenterSocket;
